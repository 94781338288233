import React from 'react';
import { Link } from 'gatsby';
import Layout from '../templates/layout';
import MetaTags from '../components/MetaTags';
import Testimonial from '../components/Testimonial';

import { getAllTestimonials } from '../../data/TestimonialsData';

const testimonialsData = getAllTestimonials();

const TITLE = 'Testimonials';

const Page = ({ path }) => {
  const TestimonialsList = ({ testimonials }) => (
    <div className="relative pt-4 pb-10 px-4 sm:px-6 lg:px-8">
      <div className="relative mx-auto max-w-5xl">
        <div className="mx-auto grid gap-5 justify-center">
          {testimonials.map((testimonial) => (
            <Testimonial
              authorName={testimonial.authorName}
              authorTitle={testimonial.authorTitle}
              authorPhotoUrl={testimonial.authorPhotoUrl}
              hideBigQuote
            >
              {testimonial.body}
            </Testimonial>
          ))}
        </div>
      </div>
    </div>
  );
  return (
    <Layout>
      <MetaTags
        title="Testimonials for Serverless Consulting Services"
        description="Client testimonials for engagements including serverless architectural consultations, audits and DevOps services for AWS serverless apps."
        path={path}
      />
      <article className="mx-auto max-w-3xl">
        <section className="mx-auto px-4 md:px-8">
          <h1 className="page-heading font-extrabold">{TITLE}</h1>
          <p>Kind words from clients, readers and colleagues.</p>
          <p className="italic">
            Care to add yours to the list? Thank-you! Email me at
            paul@serverlessfirst.com.
          </p>
        </section>
        <section className="my-8">
          <TestimonialsList testimonials={testimonialsData} />
        </section>
        <section className="mx-auto px-4 md:px-8">
          <p>
            If you’d like me to help your company get results like these, check
            out the <Link to="/services/">consulting services</Link> I offer.
            You can also check out my{' '}
            <Link to="/case-studies/">case studies page</Link> to see in more
            detail how I’ve helped some of these clients.
          </p>
        </section>
      </article>
    </Layout>
  );
};

export default Page;
