import React from 'react';

const testimonials = [
  // {
  //   id: 'TODO',
  //   sortOrder: 10,
  //   authorName: 'TODO',
  //   authorTitle: 'TODO',
  //   // authorPhotoUrl: '/img/client-logos/TODO.jpg',
  //   authorCompany: 'TODO',
  //   tags: ['serverless', 'consulting'],
  //   body: (
  //     <>
  //       <p>TODO</p>
  //     </>
  //   ),
  // },
  {
    id: 'leesman-retainer',
    sortOrder: 10,
    authorName: 'Allen Green',
    authorTitle: 'CTO at Leesman',
    // authorPhotoUrl: '/img/client-logos/TODO.jpg',
    authorCompany: 'Leesman',
    tags: ['serverless', 'consulting'],
    body: (
      <>
        <p>
          Paul started adding value to the team virtually immediately. I feel
          the upfront discovery work and the report was probably a key part of
          that. It allowed us to agree clear joint objectives and focus in on
          priority areas quickly – something I think we have managed to continue
          doing, on a quarterly basis.
        </p>
        <p>
          One major benefit is the fact that Paul engages, advises, educates and
          unblocks our other devs (both internal and external) virtually on a
          daily basis.
        </p>
        <p>
          I would 100% recommend Paul&#39;s services. Simply put, he delivers.
        </p>
      </>
    ),
  },
  {
    id: 'landnow-arch-review',
    sortOrder: 10,
    authorName: 'Peter Joseph',
    authorTitle: 'CTO at LandNow',
    authorPhotoUrl: '/img/client-logos/peter-joseph.png',
    authorCompany: 'LandNow',
    tags: ['serverless', 'consulting'],
    body: (
      <>
        <p>The main takeaway from our engagement was confidence. </p>
        <p>
          The value of having an expert who is enjoyable to work with and
          capable of providing real-world guidance and advice is immense. Paul’s
          ability to review our solution and process and instill confidence in
          our work (or help address any risks) is highly valuable.
        </p>
      </>
    ),
  },
  {
    id: '3advance-serverless-transformation',
    sortOrder: 10,
    authorName: 'Darren Gibney',
    authorTitle: 'Partner & CTO at 3Advance',
    authorPhotoUrl: '/img/client-logos/darren-gibney.jpg',
    authorCompany: '3Advance',
    tags: ['serverless', 'consulting'],
    body: (
      <>
        <p>
          From Paul’s blog posts it was clear we were bringing someone in who
          could bring us from zero to hero.
        </p>
        <p>
          Once we started working with him, we got what we expected and more.
          Things don’t stay static in tech and this was especially true of
          Serverless - AWS is continually improving and updating existing
          services and adding new ones. We didn’t want to be fighting the
          technology, we needed to deliver value for our projects and Paul
          helped us keep that balance.
        </p>
        <p>
          <strong>
            Paul’s focus on developer experience was probably the thing I most
            liked about his work with us.
          </strong>{' '}
          We frequently needed to onboard new team members and handoff projects
          to internal teams and this was key to long term success of projects.{' '}
          <strong>He raised the bar for our team</strong>, in many ways we’ve
          surpassed our previous stack in terms of IaC, CI/CD and testing.
        </p>
        <p>
          Paul knows his stuff and instantly had respect of all team members and
          leadership. Very diplomatic, change is not easy for all. Was
          professional at all times.{' '}
          <strong>
            Our team literally went from nothing on AWS and Serverless to
            feeling like there is not much we can’t do.
          </strong>
        </p>
        <p>Looking forward to our next engagement with him.</p>
      </>
    ),
  },
  {
    id: 'landkeep-patrick',
    sortOrder: 10,
    authorName: 'Patrick O',
    authorTitle: 'Co-Founder & CTO at LandKeep',
    authorPhotoUrl: '/img/client-logos/patrick-o.jpeg',
    authorCompany: 'LandKeep',
    tags: ['serverless', 'consulting'],
    body: (
      <>
        <p>
          Working with Paul was one of the best decisions we’ve made for our
          app! We knew going with a serverless architecture would be the best
          approach for our app, but due to a lack of experience, we wanted help
          from someone who was a subject matter expert in this field.
        </p>
        <p>
          I now have a lot better sense of how to build out our application’s
          infrastructure and{' '}
          <strong className="font-extrabold">
            I have the peace of mind that we are doing things in a secure,
            scalable way and won’t waste hours of development work
          </strong>{' '}
          implementing a sub optimal solution.
        </p>
        <p>
          Paul did a great job of explaining key serverless concepts,
          recommending the appropriate architecture for us, complete with all
          the AWS services to use, and how to tie them together, and he dove
          into our business-critical workflows with us to make sure we knew how
          to implement each one within a serverless context.
        </p>
        <p>
          I also liked how <em>human</em> the interactions felt, as opposed to
          something very transactional or business-like. Yes, Paul is running a
          business but at no point in our interaction did I feel like I was just
          another “client” to him or an afterthought. He took his time to answer
          all my questions, tailor his recommendations to our specific use
          cases, and showed a genuine interest in our project and organization.
          And{' '}
          <strong className="font-extrabold">
            I feel like I have “someone in my corner” that I can go to for help
            with future engagements
          </strong>
          , should I need it. Highly recommend working with Paul for your next
          serverless application!
        </p>
      </>
    ),
  },
  {
    id: 'gamehaven',
    sortOrder: 10,
    authorName: 'David Razidlo',
    authorTitle: 'Co-Owner, Game Haven',
    // authorPhotoUrl: 'TODO',
    authorCompany: 'Game Haven',
    tags: ['serverless', 'consulting'],
    body: (
      <>
        <p>
          Paul helped us transition our existing AppSync app away from Amplify
          CLI and manually created cloud resources to using the Serverless
          Framework. After a bit of hard work and help, we are comfortable with
          both decisions and feel they will be critical to our success.
        </p>
        <p>
          Paul has a deep understanding of industry best practices that goes
          beyond knowing the right thing to do. He pragmatically adapts to your
          immediate needs. He quickly understood the project and our business
          goals and he let us know when complexity was unwarranted and work was
          premature.
        </p>
      </>
    ),
  },
  {
    id: 'jonfarmand',
    sortOrder: 10,
    authorName: 'Jon Farmand',
    authorTitle: 'CTO at inOrbit',
    // authorPhotoUrl: 'TODO',
    authorCompany: 'inOrbit',
    tags: ['serverless', 'consulting'],
    body: (
      <>
        <p>
          <strong>I feel much more confident after the call</strong> that my
          application can be developed with serverless in mind, using the
          services that I was planning on using plus a few more.
        </p>
        <p>
          I have chosen to use Serverless for a new startup I’m working on and
          I’m fairly new to it so I wanted to talk through some of the
          architectural decisions with an expert.
        </p>
        <p>
          We talked through how to implement a specific workflow of my
          application using serverless, as well as ways to attack some of the
          trickier use cases in a straightforward manner. After the meeting,
          Paul sent me some resources that I can refer to in the future which
          will prove immensely beneficial. Well worth it!
        </p>
      </>
    ),
  },
  {
    id: 'autotrader-allen',
    sortOrder: 50,
    authorName: 'Allen Wales',
    authorTitle: 'Vice-President Technology at autoTRADER.ca',
    authorPhotoUrl: '/img/client-logos/allen-w.jpeg',
    authorCompany: 'autoTRADER.ca',
    tags: ['consulting'],
    body: (
      <>
        <p>
          Paul is one of the few developers that are able to make a BIG impact
          in a short period of time, single handedly worked on re-developing our
          mobile version of autoTRADER.ca which launched in record time as a
          fantastic success.
        </p>
      </>
    ),
  },
  {
    id: 'bbc-clive',
    sortOrder: 50,
    authorName: 'Clive Jones',
    authorTitle: 'Head of Software Engineering at BBC Northern Ireland',
    // authorPhotoUrl: 'TODO',
    authorCompany: 'BBC',
    tags: ['consulting'],
    body: (
      <>
        <p>
          It was important to build a functional proof of concept and create
          early architecture that would allow us to establish the project’s
          viability as quickly as possible, and we were aware that Paul had a
          practical working knowledge of modern javascript frameworks and AWS
          infrastructure we were planning to use.
        </p>
        <p>
          The proof of concept was a success. We have continued to build the
          product and have committed more internal effort to it on the way to
          its release.
        </p>
        <p>
          Paul’s early work on the site architecture has proved invaluable and
          he created excellent handover materials that the development team have
          been able to use during key decision making phases and add to them as
          and when required. He also provided useful advice on modern javascript
          best practices and improvements as we worked toward refactoring
          earlier work.
        </p>
        <p>
          Overall Paul is just a good person to have on the team, even if it is
          just for a short while. He’s honest and realistic about what can be
          achieved with his help, which was invaluable to both project
          management and system design in our tight project timescale. He worked
          with the other developers to solve issues and articulated potential
          long-term issues so that we could avoid them in the future. He ensured
          that we had sufficient handover documentation which was greatly
          appreciated.
        </p>
      </>
    ),
  },
  {
    id: 'bbc-ronan',
    sortOrder: 50,
    authorName: 'Rónán Breathnach-Cashell',
    authorTitle: 'Media Manager at BBC Rewind',
    // authorPhotoUrl: 'TODO',
    authorCompany: 'BBC',
    tags: ['consulting'],
    body: (
      <>
        <p>
          Paul made a massive impact on the BBC Rewind project and the results
          of his work can be seen in archive content now being published across
          many of the BBC’s broadcast services. He was the architect of the
          Rewind Portal, a new archive search tool which gives BBC journalists
          and programme-makers faster and easier access to the Corporation’s
          largely untapped digitised archive.
        </p>
        <p>
          His great ability for design and problem solving combined with his
          natural understanding of good UX and an eye for aesthetics allowed him
          to produce excellent results when faced with this challenging and
          complex project. Paul’s approachable style meant he was able to engage
          effectively with users of the Rewind Portal, dealing with feedback in
          a constructive and intelligent way.
        </p>
        <p>
          Always a pleasure to work with and highly talented, I would strongly
          recommend Paul’s services to any project team.
        </p>
      </>
    ),
  },
  {
    id: 'kevinxu',
    sortOrder: 20,
    authorName: 'Kevin Xu',
    authorTitle: 'Founder, Westside Labs',
    tags: ['serverless', 'workshop'],
    authorPhotoUrl: '/img/testimonials/kevin-x.jpg',
    authorCompany: 'Westside Labs',
    body: (
      <>
        <p>I’m so glad I took this workshop!</p>
        <p>
          <strong>
            I was able to apply many of the testing techniques as well as some
            larger architectural patterns to my professional and personal
            projects.
          </strong>
        </p>
        <p>
          Paul was a great teacher with deep knowledge of Serverless testing and
          applications as a whole.
        </p>
        <p>
          The other students were also very engaged which led to lots of
          productive discussions during the sessions.
        </p>
      </>
    ),
  },
  {
    id: 'soren-workshop',
    sortOrder: 20,
    authorName: 'Søren Andersen',
    authorTitle: 'Independent full-stack developer',
    tags: ['serverless', 'workshop'],
    authorPhotoUrl: '/img/testimonials/soren-a.jpg',
    // authorCompany: 'TODO',
    body: (
      <>
        <p>
          While the main focus of the workshop of course was testing - which was
          thoroughly delivered with great insight and immediately useful
          techniques - I also{' '}
          <strong>
            really enjoyed that we worked with a realistic and high quality
            sample codebase packed with typical architectural patterns
          </strong>{' '}
          including auth, queues, event buses and streams.
        </p>
        <p>I learned a lot from attending this workshop.</p>
      </>
    ),
  },
];

export const getAllTestimonials = () =>
  testimonials.sort((x, y) => x.sortOrder - y.sortOrder);

export const getTestimonial = (id) => {
  const t = testimonials.find((testimonial) => testimonial.id === id);
  if (!t) {
    throw new Error(`Testimonial with id ${id} not found`);
  }
  return t;
};
